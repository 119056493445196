import Layout from "../components/layout"
import React from "react"
import { graphql } from "gatsby"

import BlogMenu from "../components/blogMenu"
import SEO from "../components/seo"


const Post = ({ data, pageContext }) => {
  const blogpost = data.directusBlogPost
  const { previousPath, nextPath, allPath } = pageContext

return (
  <Layout>
    <div className="container w-10/12 md:w-6/8 lg:w-1/2 md:mt-10 max-w-3xl mx-auto mt-4 mb-40">
      <SEO title={ blogpost.title } />
        <div>
          <div className="font-medium">{ blogpost.title }</div>
          <div className="mt-0 mb-2 text-xs italic">{ blogpost.date }</div>
          <div className="blogpost-inner" dangerouslySetInnerHTML={{ __html: blogpost.content }}/>
        </div>
      <BlogMenu previousPath={previousPath} nextPath={nextPath} allPath={allPath}/>
    </div>
  </Layout>
) }

export const query = graphql`
  query($slug: String!) {
    directusBlogPost(slug: {eq: $slug}) {
      date(formatString: "DD.MM.YYYY")
      title
      content
    }
  }
`

export default Post
