import { Link } from "gatsby"
import RightNav from "./icons/rightNav"
import LeftNav from "./icons/leftNav"
import React from "react"


const BlogMenu = (props) => {
  const {nextPath, previousPath, allPath} = props


  return (
  <div className="fixed bottom-0 right-0">
    <div className="flex flex-col">

      <div>
        <div className={"menu-item flex flex-row items-center justify-end menu-item-navigating"}>
          {previousPath && (<LeftNav className="nav-icon left" url={previousPath}/>)}
          <Link to={allPath}>ALL POSTS</Link>
          {nextPath && (<RightNav className="nav-icon right" url={nextPath}/>)}
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center menu-item main">
          <Link className="hidden xs:flex pl-4 text-3xl" to="/">CHRISTOFFER JOERGENSEN</Link>
          <Link className="xs:hidden pl-4 text-3xl" to="/">C. JOERGENSEN</Link>
        </div>
      </div>
    </div>
  </div>
)}

export default BlogMenu
